import { BookingState, MessageType, RideOptions } from "../Chat";

export const getBookingDetailsMessages = (
  bookingState: BookingState,
  rideOptions: RideOptions,
): MessageType[] => {
  const response = [
    {
      id: Math.random(),
      content: `Here are your ride details:`,
      sender: "BOT",
    },
    {
      id: Math.random(),
      content: `Pickup address: ${bookingState.pickupAddress?.address}`,
      sender: "BOT",
    },
    {
      id: Math.random(),
      content: `Drop-off address: ${bookingState.dropoffAddress?.address}`,
      sender: "BOT",
    },
    {
      id: Math.random(),
      content: `Date: ${bookingState.date?.toLocaleDateString()}`,
      sender: "BOT",
    },
    {
      id: Math.random(),
      content: `Time: ${bookingState.date?.toLocaleTimeString()}`,
      sender: "BOT",
    },
    {
      id: Math.random(),
      content: `Car class: ${bookingState.carClass}`,
      sender: "BOT",
    },
    {
      id: Math.random(),
      content: `Total: ${bookingState.receipt?.total}`,
      sender: "BOT",
    },
  ] as MessageType[];

  if (rideOptions.driverNote) {
    response.push({
      id: Math.random(),
      content: `Driver instructions: ${rideOptions.driverNote}`,
      sender: "BOT",
    });
  }

  return response;
};
