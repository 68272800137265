export const formatDate = (date: Date) => {
  const formatter = new Intl.DateTimeFormat("en-GB", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    hour12: false,
    timeZone: "America/New_York",
  });
  const formattedDateParts = formatter.formatToParts(date);

  let dateObj = {
    month: "",
    day: "",
    year: "",
    hour: "",
    minute: "",
    second: "",
    literal: "",
  };
  formattedDateParts.forEach(({ value, type }) => {
    // @ts-ignore
    dateObj[type] = value;
  });

  return `${dateObj.year}-${dateObj.month}-${dateObj.day} ${dateObj.hour}:${dateObj.minute}:${dateObj.second}`;
};
