import React, { useEffect, useRef, useState } from "react";

import "./chat.css";
import { Message } from "./Message";
import { Button } from "./chat-elements/Button";
import { AddressInput } from "./chat-elements/AddressInput";
import {
  BookingFlowState,
  getCurrentState,
  nextState,
} from "../services/ChatStateMachine";
import { DateTimeSelector } from "./chat-elements/DateTimeSelector";
import {
  createBooking,
  FlightInfoResponseType,
  getAvailableCarClasses,
  getLeadTime,
  getPricing,
  isLocationAirport,
  LeadTimeResponse,
  PricingReceiptType,
  PricingResponseType,
} from "../services/ApiService";
import { EncodedLocation } from "../services/GoogleAutocompleteService";
import { formatDate } from "../services/utils";
import { getBookingDetailsMessages } from "./chat-logic/getBookingDetailsMessages";
import { InitialStateComponent } from "./chat-logic/InitialStateComponent";
import { AirlineInput, AirlineType } from "./chat-elements/AirlineInput";
import { FlightNumberInput } from "./chat-elements/FlightNumberInput";
import { DriverInstructions } from "./chat-elements/DriverInstructionsInput";

export type SenderType = "BOT" | "USER";

export type MessageType = {
  id: number;
  content: string;
  sender: SenderType;
};

export type FlightInfoType = {
  airline: string;
  arrival_from: string;
  flight_departure_date: string;
  landing_time: string;
  number: string;
  track_flight: boolean;
};

export type BookingState = {
  pickupAddress?: EncodedLocation;
  dropoffAddress?: EncodedLocation;
  date?: Date;
  carClass?: string;
  receipt?: PricingReceiptType;
  fligthArrivalDate?: Date;
  flightDepartureDate?: Date;
  flightNumber?: string;
  airlineCode?: string;
  airline?: FlightInfoType;
  flightInfo?: FlightInfoResponseType;
};

export enum RideType {
  AIRPORT_PICKUP = "aptpu",
  AIRPORT_DROPOFF = "aptdo",
  POINT_TO_POINT = "p2p",
  BTH = "bth",
}

const BOT_NAMES = ["Ava", "Ella", "Mia", "Lucy", "Maya"];

export type RideOptions = {
  additionalSeat: string;
  childSeat: string;
  driverNote: string;
  secondAdditionalSeat: string;
  thirdAdditionalSeat: string;
};

export const Chat: React.FC = () => {
  const [messages, setMessages] = useState<MessageType[]>([]);
  const [bookingState, setBookingState] = useState<BookingState>({});
  const [rideOptions, setRideOptions] = useState<RideOptions>({
    additionalSeat: "",
    childSeat: "",
    driverNote: "",
    secondAdditionalSeat: "",
    thirdAdditionalSeat: "",
  });
  const div = useRef<HTMLDivElement | null>(null);
  const [pointA, setPointA] = useState<EncodedLocation | undefined>(undefined);
  const [leadTime, setLeadTime] = useState<LeadTimeResponse | undefined>(
    undefined,
  );
  const [pricing, setPricing] = useState<PricingResponseType[]>([]);
  const [botOpen, setBotOpen] = useState(true);
  const [botName, setBotName] = useState("");

  const selectFirstPoint = async (address: EncodedLocation) => {
    setPointA(address);
    getLeadTime(address).then((leadTime) => {
      if (leadTime) setLeadTime(leadTime);
    });
  };

  const checkAirport = (address: EncodedLocation, isPickupAddress: boolean) => {
    console.log("Check Address Type and append info to encoded location");
    isLocationAirport(address)
      .then((addressWithAirportInfo) => {
        if (isPickupAddress) {
          submitMessage([addressWithAirportInfo.address || ""], {
            pickupAddress: addressWithAirportInfo,
          });
        } else {
          let rideType: BookingFlowState | undefined = undefined;

          if (bookingState.pickupAddress?.address_type === "AIRPORT") {
            rideType = BookingFlowState.INPUT_POINT_A_AIRPORT;
          }
          if (addressWithAirportInfo.address_type === "AIRPORT") {
            rideType = BookingFlowState.INPUT_POINT_B_AIRPORT;
          }

          console.log(`rideType: ${rideType}`);
          submitMessage(
            [addressWithAirportInfo.address || ""],
            {
              dropoffAddress: addressWithAirportInfo,
            },
            rideType,
          );
        }
      })
      .catch(console.error);
  };

  useEffect(() => {
    const botNameStringIndex =
      sessionStorage.getItem("vagent") ||
      Math.floor(Math.random() * BOT_NAMES.length).toString();
    sessionStorage.setItem("vagent", botNameStringIndex);

    let currentBotName = BOT_NAMES[parseInt(botNameStringIndex)];
    setBotName(currentBotName);

    const messages: MessageType[] = [];
    messages.push({ id: Math.random(), content: "Hi!", sender: "BOT" });
    messages.push({
      id: Math.random(),
      content: `My name is ${currentBotName}.`,
      sender: "BOT",
    });
    messages.push({
      id: Math.random(),
      content:
        "Due to high call volumes I can help you to get quote and book a ride with me.",
      sender: "BOT",
    });
    messages.push({
      id: Math.random(),
      content: "Would you like to proceed?",
      sender: "BOT",
    });
    setMessages(messages);
  }, []);

  useEffect(() => {
    if (bookingState.carClass) {
      printBookingDetails();
    }
  }, [pricing, rideOptions]);

  useEffect(() => {
    setTimeout(
      () => div.current?.scrollIntoView({ behavior: "smooth", block: "end" }),
      150,
    );
  }, [messages]);

  useEffect(() => {
    setTimeout(
      () => div.current?.scrollIntoView({ behavior: "smooth", block: "end" }),
      250,
    );
  }, [pricing]);

  const sendMessageWithoutStateUpdate = (
    msg: string[],
    sender: SenderType = "USER",
  ) => {
    const userMessages = msg.map((message) => ({
      id: Math.random(),
      content: message,
      sender,
    })) as MessageType[];

    setMessages([...messages, ...userMessages]);
  };

  const submitMessage = (
    msg: string[],
    bookingInfo: Partial<BookingState>,
    stateOverride?: BookingFlowState,
  ) => {
    const responseMessages = nextState(stateOverride).map((message) => {
      return {
        id: Math.random(),
        content: message,
        sender: "BOT",
      };
    }) as MessageType[];

    const userMessages = msg.map((message) => ({
      id: Math.random(),
      content: message,
      sender: "USER",
    })) as MessageType[];

    setMessages([...messages, ...userMessages, ...responseMessages]);

    setBookingState({ ...bookingState, ...bookingInfo });

    console.log(`bookingState: ${JSON.stringify(bookingState, null, 2)}`);
  };

  const printBookingDetails = () => {
    const bookingDetailsMessages = getBookingDetailsMessages(
      bookingState,
      rideOptions,
    );
    setMessages([...messages, ...bookingDetailsMessages]);
  };

  const renderOptionsDependingOnState = () => {
    const state = getCurrentState();
    const userDetails = sessionStorage.getItem("auth_user_details");
    switch (state) {
      case BookingFlowState.INITIAL:
        return (
          <InitialStateComponent
            botName={botName}
            onClick={() =>
              submitMessage([`Yes, let's get Quote/Book with ${botName}`], {})
            }
            userDetails={userDetails}
            proceedWithBookingButton={() => {
              submitMessage([`Login`], {});
              window.location.href = "/user/signin";
            }}
            downloadReceiptButton={() => {
              submitMessage(
                [`I need to download a receipt`],
                {},
                BookingFlowState.GET_A_RECEIPT,
              );
            }}
            notInterestedButton={() => {
              submitMessage(
                [`No, I am not interested`],
                {},
                BookingFlowState.CLOSE,
              );
              setBotOpen(false);
            }}
          />
        );
      case BookingFlowState.ASK_FOR_LOGIN:
        return (
          <React.Fragment>
            <Button
              type={"primary"}
              text={"Yes, proceed to login"}
              onClick={() => {
                submitMessage([`Yes, proceed to login`], {});
                window.location.href = "/user/signin";
              }}
            />
            <Button
              type={"secondary"}
              text={"No, proceed with Booking"}
              onClick={() => submitMessage([`No, proceed with Booking`], {})}
            />
          </React.Fragment>
        );
      case BookingFlowState.INPUT_POINT_A:
        return (
          <AddressInput
            previousAddress={undefined}
            setAddress={(address: EncodedLocation) => {
              selectFirstPoint(address);
              checkAirport(address, true);
            }}
          />
        );
      case BookingFlowState.INPUT_POINT_B:
        return (
          <AddressInput
            previousAddress={pointA}
            setAddress={(address: EncodedLocation) => {
              checkAirport(address, false);
            }}
          />
        );
      case BookingFlowState.FLIGHT_ARRIVAL_DATE:
        return (
          <DateTimeSelector
            date={new Date()}
            setDate={(date) => {
              submitMessage([`Flight date: ${date.toLocaleDateString()}`], {
                fligthArrivalDate: date,
              });
            }}
            preventBeforeDate={new Date()}
            dateOnly
          />
        );
      case BookingFlowState.FLIGHT_DEPARTURE_DATE:
        return (
          <DateTimeSelector
            date={new Date()}
            setDate={(date) => {
              submitMessage([`Flight date: ${date.toLocaleDateString()}`], {
                flightDepartureDate: date,
              });
            }}
            preventBeforeDate={new Date()}
            dateOnly
          />
        );
      case BookingFlowState.FLIGHT_NUMBER_INPUT:
        const isArrival = !bookingState.flightDepartureDate;
        const flightDate = isArrival
          ? bookingState.fligthArrivalDate?.toISOString().split("T")[0]
          : bookingState.flightDepartureDate?.toISOString().split("T")[0];
        return (
          <FlightNumberInput
            flight_airline={bookingState.airline?.airline || ""}
            flight_airport={
              (isArrival
                ? bookingState.pickupAddress?.iata
                : bookingState.dropoffAddress?.iata) || ""
            }
            flight_date={flightDate || ""}
            flight_type_is_arrival={isArrival}
            sendErrorMessages={(messages) => {
              sendMessageWithoutStateUpdate(messages, "BOT");
            }}
            setFlightInfo={(flightInfo) => {
              console.log(`flightInfo: ${JSON.stringify(flightInfo, null, 2)}`);
              submitMessage([`Flight number: ${flightInfo.flight_number}`], {
                airline: {
                  airline: flightInfo.airline.code,
                  number: flightInfo.flight_number,
                  arrival_from: flightInfo.origin_arrival_airport.iata_code,
                  flight_departure_date: new Date(
                    new Date(
                      flightInfo.departure_date_time_scheduled_local,
                    ).getTime() -
                      flightInfo.departure_time_zone_offset * 60000,
                  ).toISOString(),
                  landing_time: new Date(
                    new Date(
                      flightInfo.arrival_date_time_scheduled_local,
                    ).getTime() -
                      flightInfo.arrival_time_zone_offset * 60000,
                  ).toISOString(),
                  track_flight: true,
                },
                flightInfo,
              });
            }}
          />
        );
      case BookingFlowState.AIRLINE_CODE_INPUT:
        return (
          <AirlineInput
            code={""}
            name={""}
            submitAirline={(airline: AirlineType) => {
              submitMessage([`Airline: ${airline.name}`], {
                airline: {
                  airline: airline.code,
                  number: "",
                  arrival_from: "",
                  flight_departure_date: "",
                  landing_time: "",
                  track_flight: true,
                },
              });
            }}
          />
        );
      case BookingFlowState.AIRPORT_PICK_DATE_TIME:
        console.log({ bookingState });
        const flightDepartureDate = bookingState.flightDepartureDate
          ? new Date(bookingState.flightDepartureDate)
          : undefined;

        const fligthArrivalDate = bookingState.fligthArrivalDate
          ? new Date(bookingState.fligthArrivalDate)
          : undefined;

        console.log("> PreventBeforeDate", fligthArrivalDate);
        console.log("> PreventAfterDate", flightDepartureDate);
        return (
          <DateTimeSelector
            preventAfterDate={flightDepartureDate}
            preventBeforeDate={fligthArrivalDate}
            date={
              new Date(
                bookingState.fligthArrivalDate ||
                  bookingState.fligthArrivalDate ||
                  "",
              )
            }
            setDate={(date) => {
              getAvailableCarClasses().then((carClasses) => {
                getPricing({
                  car_classes: carClasses,
                  date: formatDate(date),
                  time_zone: "America/New_York",
                  payment_type: "CREDIT_CARD",
                  destination_location: bookingState.dropoffAddress!,
                  origin_location: bookingState.pickupAddress!,
                  luggage_count: 0,
                  number_of_passengers: 1,
                  round_trip: false,
                  use_credits: false,
                  account_id: null,
                  stops: [],
                  account_code: null,
                  customer_id: null,
                  ppc_ref_id: null,
                  promo_code: null,
                  session_id: "testing",
                })
                  .then((pricing) => {
                    setPricing(pricing);
                  })
                  .catch(() => {
                    sendMessageWithoutStateUpdate([
                      `There was an issue getting the pricing.`,
                      `Please try to use our mini booker on the website.`,
                      `Or contact us at 800-672-7676`,
                    ]);
                  });
              });
              submitMessage(
                [
                  `Selected date: ${date.toLocaleDateString()}`,
                  `Selected time: ${date.toLocaleTimeString()}`,
                ],
                {
                  date,
                },
              );
            }}
          />
        );
      case BookingFlowState.PICK_DATE_TIME:
        return (
          <DateTimeSelector
            preventBeforeDate={new Date(leadTime!.earliest_pickup_time)}
            date={new Date(leadTime!.earliest_pickup_time)}
            setDate={(date) => {
              getAvailableCarClasses().then((carClasses) => {
                getPricing({
                  car_classes: carClasses,
                  date: formatDate(date),
                  time_zone: "America/New_York",
                  payment_type: "CREDIT_CARD",
                  destination_location: bookingState.dropoffAddress!,
                  origin_location: bookingState.pickupAddress!,
                  luggage_count: 0,
                  number_of_passengers: 1,
                  round_trip: false,
                  use_credits: false,
                  account_id: null,
                  stops: [],
                  account_code: null,
                  customer_id: null,
                  ppc_ref_id: null,
                  promo_code: null,
                  session_id: "testing",
                })
                  .then((pricing) => {
                    setPricing(pricing);
                  })
                  .catch(() => {
                    sendMessageWithoutStateUpdate([
                      `There was an issue getting the pricing.`,
                      `Please try to use our mini booker on the website.`,
                      `Or contact us at 800-672-7676`,
                    ]);
                  });
              });
              submitMessage(
                [
                  `Selected date: ${date.toLocaleDateString()}`,
                  `Selected time: ${date.toLocaleTimeString()}`,
                ],
                {
                  date,
                },
              );
            }}
          />
        );
      case BookingFlowState.PICK_CAR_CLASS:
        // if (pricing.length > 0)
        //   setTimeout(
        //     () =>
        //       div.current?.scrollIntoView({ behavior: "smooth", block: "end" }),
        //     150,
        //   );
        return (
          <React.Fragment>
            {pricing.map((pricing) =>
              pricing.receipt ? (
                <Button
                  key={pricing.car_class}
                  type={"primary"}
                  text={`${pricing.car_class} - ${pricing?.receipt?.total}`}
                  onClick={() => {
                    submitMessage(
                      [`${pricing.car_class} - ${pricing?.receipt?.total}`],
                      {
                        carClass: pricing.car_class,
                        receipt: pricing.receipt,
                      },
                    );
                  }}
                />
              ) : null,
            )}
          </React.Fragment>
        );
      case BookingFlowState.CONFIRM_BOOKING:
        return (
          <Button
            type={"primary"}
            text={"Proceed to the payment page"}
            onClick={() => {
              submitMessage(["OK, take me to the payment page"], {});
              window.location.href = createBooking(bookingState, rideOptions);
            }}
          />
        );

      case BookingFlowState.DRIVER_INSTRUCTIONS:
        return (
          <DriverInstructions
            driverInstructions={rideOptions.driverNote}
            setDriverInstructions={(instructions) => {
              submitMessage([instructions || "No special instructions"], {});
              setRideOptions({ ...rideOptions, driverNote: instructions });
            }}
          />
        );
      case BookingFlowState.GET_A_RECEIPT:
        return (
          <>
            <Button
              type={"primary"}
              text={"Go to 'Get a Receipt' page"}
              onClick={() => {
                submitMessage(["Go to 'Get a Receipt' page"], {});
                window.location.href = "/get-a-receipt/";
              }}
            />
            {userDetails ? (
              <Button
                type={"secondary"}
                text={"Go to 'Account Center'"}
                onClick={() => {
                  submitMessage(["Go to 'Account Center'"], {});
                  window.location.href = "/account";
                }}
              />
            ) : null}
          </>
        );
    }
  };

  return (
    <React.Fragment>
      <div className={`chat-window chat-window-${botOpen ? "open" : "closed"}`}>
        <div className="chat-window-header">
          {`${botName} - Booking Virtual Agent`}
          <span
            className="chat-window-close-button"
            onClick={() => setBotOpen(false)}
          >
            X
          </span>
        </div>
        <div className="messages">
          {messages.map((msg, index) => (
            <Message
              id={msg.id}
              content={msg.content}
              sender={msg.sender}
              key={index}
              isFirst={index === 0 || messages[index - 1].sender !== msg.sender}
              isLast={
                index === messages.length - 1 ||
                messages[index + 1].sender !== msg.sender
              }
            />
          ))}
          {renderOptionsDependingOnState()}
          <span id={"end"} ref={div}></span>
        </div>
      </div>
      <div
        className={`chat-orb chat-orb-${!botOpen ? "visible" : "hidden"}`}
        onClick={() => setBotOpen(true)}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="35"
          height="35"
          xmlSpace="preserve"
          viewBox="0 0 256 256"
        >
          <g
            style={{
              stroke: "none",
              strokeWidth: 0,
              strokeDasharray: "none",
              strokeLinecap: "butt",
              strokeLinejoin: "miter",
              strokeMiterlimit: 10,
              fill: "none",
              fillRule: "nonzero",
              opacity: 1,
            }}
          >
            <path
              d="M83.755 3.001H6.245A6.245 6.245 0 0 0 0 9.246v50.237a6.245 6.245 0 0 0 6.245 6.245h29.208v21.27l19.095-21.27h29.208a6.245 6.245 0 0 0 6.245-6.245V9.246a6.247 6.247 0 0 0-6.246-6.245zM22.968 40.304a1.396 1.396 0 1 1-2.792 0V30.272a1.396 1.396 0 1 1 2.792 0v10.032zm41.391 4.707a6.688 6.688 0 0 1-6.681 6.681H32.323a6.688 6.688 0 0 1-6.681-6.681V26.777a6.688 6.688 0 0 1 6.681-6.681h11.282v-5.842a1.396 1.396 0 1 1 2.792 0v5.842h11.281a6.688 6.688 0 0 1 6.681 6.681v18.234zm5.465-4.707a1.395 1.395 0 1 1-2.792 0V30.272a1.395 1.395 0 1 1 2.792 0v10.032z"
              style={{
                stroke: "none",
                strokeWidth: 1,
                strokeDasharray: "none",
                strokeLinecap: "butt",
                strokeLinejoin: "miter",
                strokeMiterlimit: 10,
                fill: "#fff",
                fillRule: "nonzero",
                opacity: 1,
              }}
              transform="matrix(2.81 0 0 2.81 1.407 1.407)"
            />
            <path
              d="M38.885 34.954a1.396 1.396 0 0 1-1.396-1.396v-3.671a1.396 1.396 0 1 1 2.792 0v3.671c0 .771-.625 1.396-1.396 1.396zM51.115 34.954a1.396 1.396 0 0 1-1.396-1.396v-3.671a1.396 1.396 0 1 1 2.792 0v3.671c0 .771-.625 1.396-1.396 1.396zM45 43.985c-2.339 0-4.679-.855-6.954-2.565a1.397 1.397 0 0 1 1.678-2.232c3.6 2.707 6.955 2.706 10.552 0a1.396 1.396 0 0 1 1.678 2.232c-2.275 1.71-4.614 2.565-6.954 2.565z"
              style={{
                stroke: "none",
                strokeWidth: 1,
                strokeDasharray: "none",
                strokeLinecap: "butt",
                strokeLinejoin: "miter",
                strokeMiterlimit: 10,
                fill: "#fff",
                fillRule: "nonzero",
                opacity: 1,
              }}
              transform="matrix(2.81 0 0 2.81 1.407 1.407)"
            />
          </g>
        </svg>
      </div>
    </React.Fragment>
  );
};
