// Flow should be like this:
// 1. Ask user to proceed. If user agrees we ask to input point A
// 2. User inputs point A and we ask to input point B
// 3. User inputs point B and we ask to pick date and time
// 4. User picks date and time and we ask to pick car class
// 5. User picks car class and we ask to confirm booking

export enum BookingFlowState {
  INITIAL,
  ASK_FOR_LOGIN,
  INPUT_POINT_A,
  INPUT_POINT_A_AIRPORT,
  INPUT_POINT_B,
  INPUT_POINT_B_AIRPORT,
  PICK_DATE_TIME,
  AIRPORT_PICK_DATE_TIME,
  FLIGHT_ARRIVAL_DATE,
  FLIGHT_DEPARTURE_DATE,
  AIRLINE_CODE_INPUT,
  FLIGHT_NUMBER_INPUT,
  PICK_CAR_CLASS,
  CONFIRM_BOOKING,
  FINISHED,
  GET_A_RECEIPT,
  DRIVER_INSTRUCTIONS,
  CLOSE,
}

let currentState = BookingFlowState.INITIAL;

export const nextState = (stateOverride?: BookingFlowState): string[] => {
  const userDetails = sessionStorage.getItem("auth_user_details");
  if (stateOverride) currentState = stateOverride;
  let parsedUserDetails;
  try {
    parsedUserDetails = JSON.parse(userDetails || "");
  } catch (e) {
    console.debug("Error parsing user details", e);
  }

  switch (currentState) {
    case BookingFlowState.INITIAL:
      console.log(
        "Ask user to proceed. If user agrees we ask to input point A",
      );
      if (userDetails) {
        currentState = BookingFlowState.INPUT_POINT_A;
        return [
          `OK, let's get started, ${parsedUserDetails.first_name}`,
          "Please input your pickup address",
        ];
      } else currentState = BookingFlowState.ASK_FOR_LOGIN;
      return ["Do you have an account?", "Please login if you do"];

    case BookingFlowState.ASK_FOR_LOGIN:
      console.log("Ask user to login");
      currentState = BookingFlowState.INPUT_POINT_A;
      return ["OK, let's get started", "Please input your pickup address"];

    case BookingFlowState.INPUT_POINT_A:
      console.log("User inputs point A and we ask to input point B");
      currentState = BookingFlowState.INPUT_POINT_B;
      return ["Noted.", "Please input drop-off address"];

    case BookingFlowState.INPUT_POINT_B:
      console.log("User inputs point B and we ask to pick date and time");
      currentState = BookingFlowState.PICK_DATE_TIME;
      return [
        "Perfect",
        "Please pick date and time of the travel",
        "We will disable past dates and times for you",
      ];

    case BookingFlowState.INPUT_POINT_A_AIRPORT:
      console.log("Point A is airport, ask for flight arrival date");
      currentState = BookingFlowState.FLIGHT_ARRIVAL_DATE;
      return ["OK", "When is your flight arriving?"];

    case BookingFlowState.INPUT_POINT_B_AIRPORT:
      console.log("Point A is airport, ask for flight arrival date");
      currentState = BookingFlowState.FLIGHT_DEPARTURE_DATE;
      return ["Thanks", "When is your flight taking off?"];

    case BookingFlowState.FLIGHT_ARRIVAL_DATE:
      console.log("User inputs flight arrival date");
      currentState = BookingFlowState.AIRLINE_CODE_INPUT;
      return ["OK", "Please proceed with inputing your airline code."];

    case BookingFlowState.FLIGHT_DEPARTURE_DATE:
      currentState = BookingFlowState.AIRLINE_CODE_INPUT;
      return [
        "OK",
        "What is your airline code?",
        "You can input a airline name as well",
      ];

    case BookingFlowState.AIRLINE_CODE_INPUT:
      console.log("User inputs airline code and flight number");
      currentState = BookingFlowState.FLIGHT_NUMBER_INPUT;
      return [
        "Start typing your flight number",
        "And pick the correct one from the results",
      ];

    case BookingFlowState.FLIGHT_NUMBER_INPUT:
      console.log("User inputs airline code and flight number");
      currentState = BookingFlowState.AIRPORT_PICK_DATE_TIME;
      return ["Great!", "When do you want us to pick you up?"];

    case BookingFlowState.AIRPORT_PICK_DATE_TIME:
      console.log("User picks date and time and we ask to pick car class");
      currentState = BookingFlowState.PICK_CAR_CLASS;
      return [
        "Great!",
        "Please select your car class from the list",
        "Prices are shown for each class",
      ];

    case BookingFlowState.PICK_DATE_TIME:
      console.log("User picks date and time and we ask to pick car class");
      currentState = BookingFlowState.PICK_CAR_CLASS;
      return [
        "Great!",
        "Please select your car class from the list",
        "Prices are shown for each class",
      ];

    case BookingFlowState.PICK_CAR_CLASS:
      console.log("User picks car class and we ask to confirm booking");
      currentState = BookingFlowState.DRIVER_INSTRUCTIONS;
      return [
        "Do you have any special instructions for the driver?",
        "Add Special Instructions (eg. Pick me up on the north side of the street)",
      ];

    case BookingFlowState.DRIVER_INSTRUCTIONS:
      console.log("User inputs driver instructions");
      currentState = BookingFlowState.CONFIRM_BOOKING;
      return ["Please proceed to the payment page to complete booking process"];

    case BookingFlowState.CONFIRM_BOOKING:
      console.log("User confirms booking");
      currentState = BookingFlowState.FINISHED;
      return ["Booking completed"];

    case BookingFlowState.FINISHED:
      console.log("Booking completed");
      return ["Booking completed"];

    case BookingFlowState.GET_A_RECEIPT:
      console.log("Get a receipt page");
      const receiptsForSignedInUser = [] as string[];
      if (userDetails) {
        receiptsForSignedInUser.push("You can download receipts");
        receiptsForSignedInUser.push("from your 'Account page'");
      }
      return [
        "To download receipts for your bookings,",
        "go to a 'Get a receipt' page.",
        ...receiptsForSignedInUser,
      ];
    case BookingFlowState.CLOSE:
      console.log("Close the chat");
      currentState = BookingFlowState.INITIAL;
      return ["OK, I'm closing the chat"];
  }
};

export const getCurrentState = () => currentState;
