import { get } from "lodash";
import shortid from "shortid";

export const prepackGoogleAddressesData = async (
  googleAddresses: google.maps.places.AutocompletePrediction[],
  origin = "origin_google",
) => {
  googleAddresses = googleAddresses || [];
  return Promise.all(
    googleAddresses.map((googleObject) => {
      const key = shortid.generate();
      const address =
        get(googleObject, "description") ||
        get(googleObject, "formatted_address");
      const placeID = get(googleObject, "place_id");
      const autocompleteAddress = get(googleObject, "terms", []);
      const addressType = "LOCATIONS";
      const type = "tag";

      return {
        key,
        address,
        placeID,
        autocompleteAddress,
        addressType,
        googleObject,
        origin,
        type,
      };
    }),
  );
};

const DEFAULT_MAP_CENTER_LAT = 42.4085737;
const DEFAULT_MAP_CENTER_LNG = -71.066374;
const DEFAULT_MAP_CENTER_RADIUS = 2000;

export type AddressResultType = {
  address: string | undefined;
  autocompleteAddress: google.maps.places.PredictionTerm[] | any[];
  googleObject: google.maps.places.AutocompletePrediction;
  addressType: string;
  origin: string;
  placeID: string;
  type: string;
  key: string;
};

export const fetchPlaceDetails = (
  placeId: string,
): Promise<EncodedLocation> => {
  return new Promise((resolve, reject) => {
    try {
      if (!window.google || !window.google.maps)
        throw new Error("GoogleMaps not loaded!");
      if (!placeId || placeId === "")
        throw new Error("PlaceId is required for google place details call!");

      const {
        google: { maps: googleMaps },
      } = window;
      const placesService = new googleMaps.places.PlacesService(
        document.createElement("div"),
      );

      placesService.getDetails(
        {
          placeId,
          fields: [
            "name",
            "geometry",
            "formatted_address",
            "place_id",
            "address_components",
          ],
        },
        (placeResult: google.maps.places.PlaceResult) => {
          resolve(googleAddressToEncodedLocation(placeResult));
        },
      );
    } catch (error) {
      console.error(error);
      // Resolve as empty object if there is some issue with call google services
      // on this way we will not interupt booking process
      reject(error);
    }
  }) as Promise<EncodedLocation>;
};

export const fetchLocations = (
  query: string,
  lat?: number,
  lng?: number,
): Promise<Awaited<AddressResultType[]>> => {
  return new Promise((resolve, reject) => {
    try {
      if (!window.google || !window.google.maps)
        throw new Error("GoogleMaps not loaded!");
      if (!query || query === "")
        throw new Error("Query is required for google autocomplete call!");

      const {
        google: { maps: googleMaps },
      } = window;
      const autocompleteService = new googleMaps.places.AutocompleteService();

      const locationBias = new googleMaps.Circle({
        center: new googleMaps.LatLng(
          lat || DEFAULT_MAP_CENTER_LAT,
          lng || DEFAULT_MAP_CENTER_LNG,
        ),
        radius: DEFAULT_MAP_CENTER_RADIUS,
      });

      const request = {
        locationBias,
        input: query,
        types: ["geocode", "establishment"],
      };

      autocompleteService.getPlacePredictions(request, async (predictions) => {
        // console.log('getPlacePredictions', typeof transform);
        resolve(prepackGoogleAddressesData(predictions));
        // if (transform && (typeof transform === 'function')) {
        //   const prepacked = await transform(predictions);
        //   resolve(prepacked);
        // } else {
        //   resolve(predictions);
        // }
      });
    } catch (error) {
      console.error(error);
      // Resolve as empty array if there is some issue with call google services
      // on this way we will not interupt booking process
      resolve([]);
    }
  });
};

export type EncodedLocation = {
  address: string;
  address_type: string;
  latitude: number | undefined;
  longitude: number | undefined;
  state: string;
  street_name: string;
  city: string;
  country: string;
  iata?: string;
  number: string;
  partial_match: boolean;
  postal_code: string;
  region: string;
  locality: string;
  name: string;
  cross_street: string;
  type: "AIRPORT" | "ADDRESS";
};

export const googleAddressToEncodedLocation = (
  place: google.maps.places.PlaceResult & { partial_match?: string },
) => {
  let encodedLocation: EncodedLocation = {} as EncodedLocation;

  if (place) {
    const addressObject: Record<string, string> = {};
    const addressObjectLongName: Record<string, string> = {};

    const addressComponents = get(place, "address_components", []);
    addressComponents.forEach((addressComponent) => {
      const types = get(addressComponent, "types", []);
      types.forEach((type) => {
        addressObject[type] = get(addressComponent, "short_name");
        addressObjectLongName[type] = get(addressComponent, "long_name");
      });
    });

    encodedLocation.address = get(place, "formatted_address") || "";
    encodedLocation.address_type = "ADDRESS";

    const location = get(place, "geometry.location");
    encodedLocation.latitude = location ? location.lat() : undefined;
    encodedLocation.longitude = location ? location.lng() : undefined;

    encodedLocation.state = get(addressObject, "administrative_area_level_1");
    encodedLocation.street_name =
      get(addressObject, "route") || get(place, "name");
    encodedLocation.city =
      get(addressObjectLongName, "locality") ||
      get(addressObjectLongName, "sublocality") ||
      get(addressObjectLongName, "neighborhood");
    encodedLocation.country = get(addressObject, "country");
    encodedLocation.number = get(addressObject, "street_number");
    encodedLocation.partial_match = !!place.partial_match;
    encodedLocation.postal_code = get(addressObject, "postal_code");
    encodedLocation.region = get(addressObject, "state");
    encodedLocation.locality = get(addressObject, "sublocality_level_1");
    encodedLocation.name =
      get(place, "name") || get(place, "formatted_address") || "";
    if (!get(addressObject, "street_number")) {
      const formattedAddress = get(place, "formatted_address", "");
      encodedLocation.cross_street = formattedAddress.split(",")[0];
    }
  }

  // console.log({ encodedLocation });
  return encodedLocation;
};
