import { MessageType } from "./Chat";

import "./message.css";

export const Message: React.FC<
  MessageType & { isFirst: boolean; isLast: boolean }
> = ({ content, sender, id, isFirst, isLast }) => {
  const messageType = sender === "BOT" ? "received" : "sent";
  const messagePosition = isFirst ? "first" : isLast ? "last" : "middle";
  return (
    <div className={`message-${messageType}`}>
      <div
        className={`message-${messageType}-text message-${messageType}-${messagePosition}`}
      >
        {content}
      </div>
    </div>
  );
};
